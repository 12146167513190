import { useEffect } from "react";

import { Box } from "@mui/material";
import ExpiredToken from "@/components/ExpiredToken";
import { Page } from "@/components/Page";
import { HeroRequestAFlight } from "@/components/PageIndex/HeroRequestAFlight";
import { UnconfirmedUserModal } from "@/components/PageIndex/UnconfirmedUserModal";
import { useRouter } from "next/router";

import { useAnalytics, useAuthentication } from "@fllite/ui/hooks";
import { Layout } from "@fllite/ui/components";

const PageIndex = () => {
  const { user } = useAuthentication();
  const { query } = useRouter();
  const confirmationToken = query["confirmation-token"] as string;
  const { track } = useAnalytics();

  useEffect(() => {
    switch (true) {
      case user !== false && user?.confirmed:
        track("pageview", { page: "dashboard" });
        break;
      case confirmationToken === undefined:
        track("pageview", { page: "expired_token" });
        break;
      default:
        track("pageview", { page: "confirmation_page" });
        break;
    }
  }, [user !== false && user?.id, confirmationToken]);

  return (
    <>
      <Layout allowOverflow>
        <Page
          backgroundStyle="pilot"
          hasFooter={user !== false && user?.confirmed}
          unconfirmedBackgroundType={
            user !== false && user?.confirmed
              ? undefined
              : user !== false && user?.lastBusinessCase?.id
                ? "trip"
                : "no-trip"
          }
          expired={!!confirmationToken}
        >
          {user !== false && user?.confirmed ? (
            <Box pb={10}>
              <HeroRequestAFlight />
            </Box>
          ) : confirmationToken ? (
            <ExpiredToken />
          ) : (
            <UnconfirmedUserModal />
          )}
        </Page>
      </Layout>
    </>
  );
};

export default PageIndex;
